<template>
	<div class="process-file-list">
		<Spin fix v-if="progressListLoading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="文件名称">
				<Input placeholder="请输入文件名称" v-model="searchParams.name" />
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem
				:span="1"
				label="文件状态"
				v-if="type !== 'latestFiles'"
			>
				<Select v-model="searchParams.status" :transfer="true">
					<Option
						v-for="(name, index) in status"
						:key="index"
						:value="index"
						:label="name"
					>
					</Option>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="newColumns"
			:data="listData"
			:page="false"
		>
			<template slot-scope="{ row }" slot="status">
				<span style="color: #2db7f5">
					{{ getStatus(row.status).tooltip }}
				</span>
				<!--        <Tooltip :content="getStatus(row.status).tooltip">-->
				<!--          <span v-if="getStatus(row.status).type === 'span'"-->
				<!--            :style="getStatus(row.status).style">-->
				<!--            {{ getStatus(row.status).content }}-->
				<!--          </span>-->
				<!--          <img v-else-->
				<!--               :style="getStatus(row.status).style"-->
				<!--               :src="getStatus(row.status).content" />-->
				<!--        </Tooltip>-->
			</template>
		</CtmsDataGrid>
		<!--表单预览-->
		<Preview
			:visible="previewVisible"
			type="document"
			@onCancel="previewVisible = false"
			:id="previewId"
		></Preview>
		<!--  pdf 预览-->
		<DocumentPreview
			:visible="documentPreviewVisible"
			:documentSrc="documentSrc"
			:id="getRandomId"
			@onCancel="documentPreviewVisible = false"
		></DocumentPreview>
	</div>
</template>

<script>
import api from "@/api/project/fileList"
import { transformSizeUnit } from "@/utils/util"
import { mapState } from "vuex"
import orangeImg from "@/assets/images/project/light1.svg"
import yellowImg from "@/assets/images/project/light2.svg"
import redImg from "@/assets/images/project/light3.svg"
import greenImg from "@/assets/images/project/light4.svg"
import DocumentPreview from "@/components/BusinessComponents/Document/DocumentPreview.vue"
import Preview from "@/views/Setting/Form/Preview.vue"
import docType from "@/config/docType"

const { getPrjFileList, getPrjLatestFileList } = api
export default {
	name: "fileList",
	components: {
		DocumentPreview,
		Preview
	},
	props: {
		proId: {
			type: String,
			default: ""
		},
		type: {
			default: ""
		}
	},
	data() {
		const status = [
			"非审核",
			"待审核",
			"审核通过",
			"审核未通过",
			"流程文件",
			"旧版本"
		]
		return {
			progressListLoading: false,
			searchParams: {
				name: "",
				status: ""
			},
			oldSearchParams: {
				name: "",
				status: ""
			},
			status,
			listData: [],
			newColumns: [],
			columns: [
				{
					title: "名称",
					key: "name",
					minWidth: 200,
					tooltip: true,
					sortable: "custom",
					sortType: "",
					render: (h, params) =>
						h(
							"span",
							{
								style: {
									cursor: "pointer"
								},
								on: {
									click: e => {
										this.handlePreview(e, params.row)
									}
								}
							},
							params.row.name
						)
				},
				{
					title: "路径",
					minWidth: 200,
					key: "viewPath",
					tooltip: true
				},
				{
					title: "大小",
					render: (h, params) =>
						h(
							"span",
							params.row.size ? `${transformSizeUnit(params.row.size)}` : "--"
						),
					minWidth: 120
				},
				{
					title: "创建日期",
					minWidth: 170,
					key: "createdTime"
				},
				{
					title: "权限状态",
					minWidth: 100,
					key: "roleIds ",
					render: (h, params) =>
						h("span", params.row.roleIds ? "已配置" : "未配置")
				},
				{
					title: "文件版本",
					key: "version",
					minWidth: 150,
					tooltip: true,
					align: "center",
					render: (h, { row }) => h("span", row.version || "--")
				},
				{
					title: "文件状态",
					key: "status",
					minWidth: 200,
					align: "center",
					slot: "status"
				},
				{
					title: "操作",
					key: "action",
					width: 80,
					fixed: "right",
					renderButton: params => {
						const btnList = [
							{
								label: "下载",
								on: {
									click: () => {
										const downloadUrl = `${this.$baseUrl}/project/${this.projectId}/docfile/${params.row.id}/download?token=${this.$store.state.user.token}`
										window.open(downloadUrl)
									}
								}
							}
						]
						if (!params.row.suffix) {
							btnList[0] = null
						}
						return btnList.filter(item => !!item)
					}
				}
			],
			previewVisible: false,
			previewId: "",
			documentPreviewVisible: false,
			documentSrc: "",
			previewUrl: `${this.$baseUrl}/document/docfile`,
			downloadUrl: `${this.$baseUrl}/document/docfile`
		}
	},
	computed: {
		...mapState("project", ["infoHeight"]),
		...mapState({
			dataGridHeight(state) {
				if (this.projectId) {
					return state.contentHeight - 80 - this.infoHeight
				}
				return state.contentHeight - 180
			}
		}),
		projectId() {
			return this.proId || this.$route.params.projectId
		},
		getRandomId() {
			return `prev${new Date().getTime().toString(32).toUpperCase()}`
		}
	},
	created() {
		this.newColumns = [...this.columns]
		if (this.type === "latestFiles") {
			this.newColumns.splice(6, 1)
		}
	},
	mounted() {
		this.initList()
	},
	methods: {
		async initList() {
			this.progressListLoading = true
			let res = false
			if (this.type === "latestFiles") {
				res = await getPrjLatestFileList(this.projectId, {
					nameFuzzy: this.oldSearchParams.name
				})
			} else {
				res = await getPrjFileList(this.projectId, {
					...this.oldSearchParams
				})
			}
			if (res) {
				this.listData = res.data || []
			}
			this.progressListLoading = false
		},
		getStatus(status) {
			const obj = {
				type: "span", // 显示 span,  显示img
				content: "--", // 显示内容
				tooltip: "无需审核" // tooltip内容
			}
			switch (status) {
				case 0:
					obj.type = "span"
					obj.content = "--"
					obj.tooltip = "无需审核"
					break
				case 1:
					obj.type = "img"
					obj.content = yellowImg
					obj.tooltip = "待审核"
					break
				case 2:
					obj.type = "img"
					obj.content = greenImg
					obj.tooltip = "审核通过"
					break
				case 3:
					obj.type = "span"
					obj.content = "X"
					obj.tooltip = "审核不通过"
					break
				case 4:
					obj.type = "img"
					obj.content = orangeImg
					obj.tooltip = "流程中文件"
					break
				case 5:
					obj.type = "img"
					obj.content = redImg
					obj.tooltip = "被版本更新文件"
					break
				default:
					obj.type = "span"
					obj.content = "--"
					obj.tooltip = "无需审核"
					break
			}

			if (obj.type === "img") {
				obj.style = {
					width: "50px",
					height: "50px",
					padding: "8px"
				}
			} else if (status === 3) {
				obj.style = {
					color: "#f00",
					fontSize: "20px"
				}
			}
			return obj
		},
		handlePreview(e, row) {
			if (row.type === 3) {
				// 表单预览
				this.previewVisible = true
				this.previewId = row.entityId
			} else {
				const suffix = row.suffix ? row.suffix.toUpperCase() : ""
				if (docType.previewTypes.indexOf(suffix) > -1) {
					this.documentSrc = `${this.previewUrl}/${row.id}/inline?token=${this.$store.state.user.token}`
					this.documentPreviewVisible = true
				} else {
					const downloadUrl = `${this.$baseUrl}/project/${this.projectId}/docfile/${row.id}/download?token=${this.$store.state.user.token}`
					window.open(downloadUrl)
				}
			}
		},
		// 重置
		reset() {
			this.searchParams = {
				name: "",
				status: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.$nextTick(() => {
				this.initList()
			})
		}
	}
}
</script>

<style scoped></style>
