import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/project"

export default {
	...commonApi(moduleName),
	// 获取项目下最新版本文件列表
	getPrjLatestFileList: (projectId, params) => {
		if (!projectId || !params) {
			throw new Error("Invalid projectId or params")
		}
		return request(`${moduleName}/${projectId}/latest-files`, {
			method: "get",
			params
		})
	},
	// 获取项目下流程文件列表
	getPrjFileList: (projectId, params) => {
		if (!projectId || !params) {
			throw new Error("Invalid projectId or params")
		}
		return request(`${moduleName}/${projectId}/docfile/files`, {
			method: "get",
			params
		})
	},
	// 获取流程节点下的文件列表
	getFileList: (projectId, params) => {
		if (!projectId || !params) {
			throw new Error("Invalid projectId or params")
		}
		return request(`${moduleName}/${projectId}/docfile/files-level`, {
			method: "get",
			params
		})
	}
}
